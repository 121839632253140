import(/* webpackMode: "eager" */ "/app/components/Button/Button.module.css");
;
import(/* webpackMode: "eager" */ "/app/components/CountryCode/CountryCode.module.css");
;
import(/* webpackMode: "eager" */ "/app/app/[lang]/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["MultiLevelSelect"] */ "/app/components/MultiLevelSelect/MultiLevelSelect.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PasswordField"] */ "/app/components/PasswordField/PasswordField.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SimpleStatBlock"] */ "/app/components/SimpleStatBlock/SimpleStatBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Table"] */ "/app/components/Table/Table.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/TagInput/TagInput.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/TextInput/TextInput.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["Translations","DictionaryContext"] */ "/app/components/Translations/Translations.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Button"] */ "/app/node_modules/@mantine/core/esm/components/Button/Button.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Flex"] */ "/app/node_modules/@mantine/core/esm/components/Flex/Flex.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Select"] */ "/app/node_modules/@mantine/core/esm/components/Select/Select.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Text"] */ "/app/node_modules/@mantine/core/esm/components/Text/Text.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["TextInput"] */ "/app/node_modules/@mantine/core/esm/components/TextInput/TextInput.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["ColorSchemeScript"] */ "/app/node_modules/@mantine/core/esm/core/MantineProvider/ColorSchemeScript/ColorSchemeScript.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["MantineProvider"] */ "/app/node_modules/@mantine/core/esm/core/MantineProvider/MantineProvider.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mantine/core/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["Notifications"] */ "/app/node_modules/@mantine/notifications/esm/Notifications.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["cleanNotifications","cleanNotificationsQueue","createNotificationsStore","hideNotification","notifications","notificationsStore","showNotification","updateNotification","updateNotificationsState","useNotifications"] */ "/app/node_modules/@mantine/notifications/esm/notifications.store.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mantine/notifications/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["theme","variableOverrides"] */ "/app/theme.ts");
